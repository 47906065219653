import './bootstrap';

setTimeout(function () {
    var element = document.querySelector('.hide-alert');

    if (element) {
        // Set the element's initial opacity to 1
        element.style.opacity = 1;

        // Gradually reduce the element's opacity to 0 over the course of 5 seconds (5000 milliseconds)
        var interval = setInterval(function () {
            element.style.opacity -= 0.1;
            if (element.style.opacity <= 0) {
                clearInterval(interval);
                element.style.display = 'none';
            }
        }, 50); // Reduce the element's opacity by 0.1 every 1 second (1000 milliseconds)
    }

}, 2000); // Start the animation after 5 seconds (5000 milliseconds)
